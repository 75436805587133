<template>
    <div>
        <LiefengContent>
            <template v-slot:title>{{ "转换原始数据" }}</template>
            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="table.talbeColumns"
                    :tableData="table.tableData"
                    :loading="table.loading"
                    :fixTable="true"
                    :curPage="table.page"
                    :total="table.total"
                    :pagesizeOpts="[20, 50, 100]"
                    :page-size="table.pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: { LiefengContent, LiefengTable },
    data() {
        return {
            search: {},
            table: {
                talbeColumns: [
                    {
                        key: "zoneName",
                        align: "center",
                        title: "区",
                    },
                    {
                        key: "streetName",
                        align: "center",
                        title: "街道",
                    },
                    {
                        key: "communityName",
                        align: "center",
                        title: "社区",
                    },
                    {
                        key: "name",
                        align: "center",
                        title: "表格名称",
                    },
                    {
                        key: "importUserName",
                        align: "center",
                        title: "导入人",
                    },
                    {
                        key: "gmtCreate",
                        align: "center",
                        title: "导入时间",
                        render: (h, params) => {
                            return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                        },
                    },
                    {
                        key: "status",
                        align: "center",
                        title: "状态",
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: params.row.status == 1 ? "#000" : "red",
                                    },
                                },
                                params.row.status == 1 ? "已处理" : "未处理"
                            )
                        },
                    },
                    {
                        title: "操作",
                        align: "center",
                        width: 220,
                        render: (h, params) => {
                            return h("div", [
                                h(
                                    "Button",
                                    {
                                        props: {
                                            type: "info",
                                            size: "small",
                                        },
                                        nativeOn: {
                                            click: () => {
                                                this.matching(params.row)
                                            },
                                        },
                                        style:{
                                            marginRight: "10px",
                                        }
                                    },
                                    "匹配转换"
                                ),
                                h(
                                    "Button",
                                    {
                                        props: {
                                            type: "info",
                                            size: "small",
                                        },
                                        nativeOn: {
                                            click: () => {
                                                this.deleteRow(params.row)
                                            },
                                        },
                                    },
                                    "清空数据"
                                ),
                            ])
                        },
                    },
                ],
                tableData: [],
                loading: false,
                page: 1,
                total: 0,
                pageSize: 20,
                currentPage: 1,
            },
            timer: null,
        }
    },

    async created() {
        await this.getList()
    },
    methods: {
        // 切换分页事件
        hadlePageSize(val) {
            this.table.page = val.page
            this.table.pageSize = val.pageSize
            this.getList()
        },

        // 接口部分
        getList() {
            this.table.loading = true
            try {
                this.$get("/syaa/pc/sy/user/gridManage/listSheetImpByPage", {
                    // archivesId: "",
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCode: this.$route.query.orgCode,
                    oemCode: parent.vue.oemInfo.oemCode,
                    page: this.table.page,
                    pageSize: this.table.pageSize,
                }).then(res => {
                    this.table.loading = false
                    if (res && res.code == 200) {
                        this.table.tableData = res.dataList
                        this.table.total = res.maxCount
                        this.table.currentPage = res.currentPage
                    } else {
                        this.$Message.error({
                            content: "获取数据失败",
                            background: true,
                        })
                        return
                    }
                })
            } catch (e) {
                this.table.loading = false
                this.$Message.error({
                    content: "获取数据失败",
                    background: true,
                })
                return
            }
        },

        // 匹配转化
        matching(data) {
            this.$core.openLayerFrame(
                {
                    type: 2,
                    title: "匹配转换",
                    content: `/page#/matchingresidentdatalw?archivesId=${data.id}&orgCode=${this.$route.query.orgCode}`,
                    area: ["100%", "100%"],
                },
                () => {
                    this.table.page = this.table.currentPage
                    this.getList()
                }
            )
        },
        // 清空数据
        deleteRow(data) {
            this.$Modal.confirm({
                title: "清空数据提醒",
                content: "<p>需确认数据姓名、手机号、身份证号、门牌号一一对应，若不合格，则可清空数据</p>",
                onOk: () => {
                    this.$Message.loading({
                        content: "正在清空，请稍等...",
                        duration: 0,
                    })
                    try {
                        this.$post("/syaa/pc/sy/user/gridManage/clearArchiveDataById", {
                            archiveId: data.id,
                        }).then(res => {
                            this.$Message.destroy()
                            if (res && res.code == 200) {
                                this.$Message.success({
                                    content: "清空数据成功",
                                    background: true,
                                })
                                this.table.page = this.table.currentPage
                                this.getList()
                                return
                            } else {
                                this.$Message.error({
                                    content: "清空数据失败",
                                    background: true,
                                })
                                return
                            }
                        })
                    } catch (err) {
                        this.$Message.destroy()
                        this.$Message.error({
                            content: "清空数据失败",
                            background: true,
                        })
                        return
                    }
                },
            })
        },
    },
}
</script>

<style lang="less" scoped>
.export-modal {
    /deep/ #modal_contentarea {
        overflow: visible !important;
        height: 270px;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
</style>